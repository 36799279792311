import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Loadable from "@loadable/component";

import Fade from "react-reveal/Fade";
import { useLax } from "use-lax";

import Layout from "../../components/Layout";
import Hero from "../../components/Hero";
import IntroImage from "../../components/IntroImage";
import Button from "../../components/Button";
import Cookies from "js-cookie";

const LoadableMap = Loadable(() => import("../../components/Map"));
const LoadableGallery = Loadable(() =>
  import("../../components/Gallery/Gallery")
);

const EnglishPage = props => {
  const { location } = props;
  const lang = "en";

  // Set language to cookie
  Cookies.remove("selectLang");
  Cookies.set("selectLang", "en");

  // Page setting
  const pageSlug = "home-en";
  const subTitle = "Traditional Japanese House in Nagano";
  const address = "542-1 Nakaminowa, Kamiina-gun, Nagano, 399-4601";
  const heroCopy =
    '"Kominka" - is a general term for an old house in Japanese.';

  const data = useStaticQuery(graphql`
    query {
      historyImage1: file(relativePath: { eq: "home/img_history_01.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage2: file(relativePath: { eq: "home/img_history_02.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage3: file(relativePath: { eq: "home/img_history_03.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage4: file(relativePath: { eq: "home/img_history_04.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage5_1: file(relativePath: { eq: "home/img_history_05_1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage5_2: file(relativePath: { eq: "home/img_history_05_2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage6: file(relativePath: { eq: "home/img_history_06.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  // Initialize lax
  useLax({
    breakpoints: { small: 0, large: 768 },
  });

  return (
    <Layout
      location={location}
      lang={lang}
      pageSlug={pageSlug}
      subTitle={subTitle}
    >
      <Hero heroCopy={heroCopy} lang={lang} />

      <div className="section-wrap">
        <section id="intro" className="section-intro">
          <Fade>
            <div className="container-sm">
              <span className="intro__head--caption">ABOUT</span>
              <h2 className="intro__head font-copy">
                Preserving the house for future
              </h2>
            </div>
          </Fade>
          <div className="intro__text container-sm">
            <Fade>
              <p>
                Kominka Misawaya<small>（古民家 箕澤屋）</small>is a traditional
                Japanese house built in 1861, at the end of Edo period.
                “Kominka” is a Japanese term for an old house and “Misawaya” is
                the name of this particular house.
              </p>

              <p>
                Like most Kominkas, this one was built from wood, making it
                particularly vulnerable to fires and earthquakes. Kominka
                Misawaya survived many disasters over time and managed to retain
                its original “Doma” (mud room) and “Iroi” (sunken fireplace)
                which are customary in such structures. Once you step inside,
                you feel a sense of life and history.
              </p>

              <p>
                Our goal is to preserve the house and to give visitors from afar
                a taste of traditional Japanese culture.
              </p>
            </Fade>
          </div>
        </section>

        <section className="section-bg">
          <Fade>
            <IntroImage />
          </Fade>
        </section>

        <section id="history" className="section-history">
          <div className="history__inner container-sm">
            <div className="period__wrap">
              <Fade left distance={"20px"} delay={100}>
                <h3 className="history__head">Timeline of Misawaya</h3>
              </Fade>
              <div className="period__bg"></div>
              <div className="period period-01">
                <div className="period__vertical lax" data-lax-preset="fadeIn">
                  End of Edo
                </div>
                <div className="period__text">
                  <div>
                    <div
                      className="period__text--head lax"
                      data-lax-preset="fadeIn"
                    >
                      <div className="head-circle">
                        <div className="circle__outer pointOut"></div>
                        <div className="circle__inner"></div>
                      </div>
                      <span className="head-line"></span>
                      <h4 className="head-copy">The present house was built</h4>
                    </div>
                  </div>
                  <div
                    className="period__text--description lax"
                    data-lax-preset="fadeIn"
                  >
                    The present house was built in 1861 after the previous house
                    was burned down.
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="1350 0, 1450 1"
                  data-lax-translate-y_small="1350 0, 2350 -50"
                  data-lax-opacity_large="1500 0, 1600 1"
                  data-lax-translate-y_large="1500 0, 2500 -50"
                >
                  <Img
                    fluid={data.historyImage1.childImageSharp.fluid}
                    className="period__image__img"
                    alt="The present house was built"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="1300 0, 1400 1"
                  data-lax-translate-y_small="1300 0, 2300 50"
                  data-lax-opacity_large="1500 0, 1600 1"
                  data-lax-translate-y_large="1500 0, 2500 50"
                >
                  1861
                </div>
              </div>

              <div className="period period-02">
                <div
                  className="period__vertical period__vertical--meiji lax"
                  data-lax-preset="fadeIn"
                >
                  Meiji
                </div>
                <div
                  className="period__vertical period__vertical--taisho lax"
                  data-lax-preset="fadeIn"
                >
                  Taisho
                </div>
                <div className="period__text">
                  <div
                    className="period__text--head lax"
                    data-lax-preset="fadeIn"
                  >
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">The prosperous Obara Family</h4>
                  </div>
                  <div
                    className="period__text--description lax"
                    data-lax-preset="fadeIn"
                  >
                    In the era of Edo and Meiji, Misawaya was owned and lived in
                    by the Obara family whose members built various flourishing
                    businesses, including a brewery.
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="1800 0, 1900 1"
                  data-lax-translate-y_small="1800 0, 2800 -50"
                  data-lax-opacity_large="1850 0, 1950 1"
                  data-lax-translate-y_large="1850 0, 2850 -50"
                >
                  <Img
                    fluid={data.historyImage2.childImageSharp.fluid}
                    className="period__image__img"
                    alt="The prosperous Obara Family"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="1750 0, 1850 1"
                  data-lax-translate-y_small="1850 0, 2850 50"
                  data-lax-opacity_large="1900 0, 2000 1"
                  data-lax-translate-y_large="1900 0, 2900 50"
                >
                  1900
                </div>
              </div>

              <div className="period period-03">
                <div className="period__vertical lax" data-lax-preset="fadeIn">
                  Showa
                </div>
                <div className="period__text lax" data-lax-preset="fadeIn">
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">Vacancy</h4>
                  </div>
                  <div className="period__text--description">
                    The last member of the Obara family at Misawaya, Chikae,
                    left the house vacant because she was sick.
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="2150 0, 2250 1"
                  data-lax-translate-y_small="2150 0, 3150 -50"
                  data-lax-opacity_large="2400 0, 2500 1"
                  data-lax-translate-y_large="2400 0, 3400 -50"
                >
                  <Img
                    fluid={data.historyImage3.childImageSharp.fluid}
                    className="period__image__img"
                    alt="Vacancy"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="2050 0, 2150 1"
                  data-lax-translate-y_small="2050 0, 3050 50"
                  data-lax-opacity_large="2300 0, 2400 1"
                  data-lax-translate-y_large="2250 0, 3250 50"
                >
                  1976
                </div>
              </div>

              <div className="period period-04">
                <div className="period__vertical lax" data-lax-preset="fadeIn">
                  Heisei
                </div>
                <div className="period__text">
                  <div
                    className="period__text--head lax"
                    data-lax-preset="fadeIn"
                  >
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">
                      The first volunteer preservation group
                    </h4>
                  </div>
                  <div
                    className="period__text--description lax"
                    data-lax-preset="fadeIn"
                  >
                    In 1990, the man who owned the house at that time asked
                    National Trust to preserve it. After that, neighbors who
                    liked Misawaya gathered and created the first volunteer
                    group “Misawaya wo Mamoru Kai” preserving the house and
                    organizing public events.
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="2650 0, 2750 1"
                  data-lax-translate-y_small="2650 0, 3650 -50"
                  data-lax-opacity_large="3000 0, 3100 1"
                  data-lax-translate-y_large="3000 0, 4000 -50"
                >
                  <Img
                    fluid={data.historyImage4.childImageSharp.fluid}
                    className="period__image__img"
                    alt="The first volunteer preservation group"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="2550 0, 2650 1"
                  data-lax-translate-y_small="2550 0, 3550 50"
                  data-lax-opacity_large="3100 0, 3200 1"
                  data-lax-translate-y_large="3100 0, 4100 50"
                >
                  1997
                </div>
              </div>

              <div className="period period-05">
                <div className="period__text lax" data-lax-preset="fadeIn">
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">New members take over</h4>
                  </div>
                  <div className="period__text--description">
                    We – descendants of the Obara family — took over the house’s
                    preservation from previous volunteer members who were too
                    old to continue. Shortly after, we repaired some parts of
                    the house to make them accessible to the public.
                  </div>
                </div>
                <div
                  className="period__image period__image--1 lax"
                  data-lax-opacity_small="3100 0, 3200 1"
                  data-lax-translate-y_small="3100 0, 4100 -20"
                  data-lax-opacity_large="3450 0, 3550 1"
                  data-lax-translate-y_large="3450 0, 4450 -20"
                >
                  <Img
                    fluid={data.historyImage5_1.childImageSharp.fluid}
                    className="period__image__img"
                    alt="New members take over1"
                  />
                </div>
                <div
                  className="period__image period__image--2 lax"
                  data-lax-opacity_small="3200 0, 3300 1"
                  data-lax-translate-y_small="3200 0, 4200 -50"
                  data-lax-opacity_large="3600 0, 3700 1"
                  data-lax-translate-y_large="3600 0, 4600 -80"
                >
                  <Img
                    fluid={data.historyImage5_2.childImageSharp.fluid}
                    className="period__image__img"
                    alt="現メンバー引継2"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="3050 0, 3150 1"
                  data-lax-translate-y_small="2900 0, 3900 50"
                  data-lax-opacity_large="3450 0, 3550 1"
                  data-lax-translate-y_large="3450 0, 4550 50"
                >
                  2014
                </div>
              </div>

              <div className="period period-06">
                <div className="period__vertical lax" data-lax-preset="fadeIn">
                  Reiwa
                </div>
                <div className="period__text">
                  <div
                    className="period__text--head lax"
                    data-lax-preset="fadeIn"
                  >
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">New projects</h4>
                  </div>
                  <div
                    className="period__text--description lax"
                    data-lax-preset="fadeIn"
                  >
                    Inspired by our work, new volunteers gathered and started
                    innovative projects such as spring cleaning and summer
                    events.
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="3500 0, 3600 1"
                  data-lax-translate-y_small="3500 0, 4500 -50"
                  data-lax-opacity_large="4250 0, 4350 1"
                  data-lax-translate-y_large="4250 0, 5250 -80"
                >
                  <Img
                    fluid={data.historyImage6.childImageSharp.fluid}
                    className="period__image__img"
                    alt="New projects"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="3400 0, 3500 1"
                  data-lax-translate-y_small="3900 0, 4900 50"
                  data-lax-opacity_large="4050 0, 4150 1"
                  data-lax-translate-y_large="4050 0, 5050 50"
                >
                  2017
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="projects" className="section-projects">
          <div className="projects__intro__wrap">
            <div className="container-sm">
              <Fade>
                <div className="projects__intro">
                  <span className="projects__intro__head--caption">
                    PROJECTS
                  </span>
                  <h2 className="projects__intro__head">
                    Increasing community participation
                  </h2>
                  <div className="projects__intro__text">
                    <p>
                      We have been trying to find sustainable ways to preserve
                      the house to ensure Misawaya will continue to be a
                      valuable place where people can gather to learn about,
                      protect and re-vitalize an essential part of Japanese
                      history.
                    </p>
                    <p
                      style={{
                        color: "#ec5840",
                        border: "#ec5840 1px solid",
                        padding: "6px 15px 10px",
                        lineHeight: 1.5,
                        marginTop: "4rem",
                      }}
                    >
                      Due to the COVID-19 pandemic, we are going to cancel all
                      public events in 2022 however our core members are still
                      working on the preservation work.
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section id="gallery" className="section-gallery">
          <div className="gallery__inner">
            <Fade>
              <div className="gallery__image">
                <LoadableGallery />
              </div>
            </Fade>
          </div>
        </section>

        <section id="location" className="section-lacation">
          <h2 className="location__head only-for-small container">Location</h2>
          <div className="location__content container">
            <div className="location__map">
              <LoadableMap />
            </div>
            <div className="location__text">
              <div className="inner">
                <h2 className="location__head only-for-medium">Location</h2>
                <div>
                  <h3 className="text__head">
                    Kominka Misawaya <br className="only-for-small" />
                    (Former Obara Family)
                  </h3>
                  <p>{address}</p>
                  <div className="text--direction">
                    <p>
                      4-minute walk from JR Iida line Sawa Station(From
                      Shinjuku, appx 3 hours by train)
                    </p>
                    <p>5-minute drive from Ihoku IC of Chuo-Jidosha-do</p>
                  </div>
                  <div className="location__button">
                    <a
                      className="location__button__link nohover"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://goo.gl/maps/GZUs7oZErXpPLaFDA"
                    >
                      <Button text={"GoogleMaps"} icon={"external"} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="blog" className="section-blog">
          <div className="blog__content container">
            <h2 className="blog__head">BLOG</h2>
            <p>
              We have been posting blog posts about renovation, etc since we
              took over the house. (Available in Japanese only)
            </p>
            <div className="blog__button">
              <a
                href="https://blog.misawaya.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="blog__button__link nohover"
              >
                <Button text={"Read Blog"} icon={"external"} />
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default EnglishPage;
